<template>
  <div>
    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="mt-5 mb-0" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID </label>
              <a-input placeholder="Digite" v-model="creditLetter.filters.id" />
            </div>
          </a-col>
          <!--
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID do Voyage</label>
              <a-input
                placeholder="Digite"
                v-model="creditLetter.filters.voyageId"
              />
            </div>
          </a-col> -->

          <a-col :span="6">
            <HayaSelectField
              label="Cliente"
              placeholder="Selecione ou busque id ou nome"
              :list="mapCustomersList(customers.list)"
              :allowClear="true"
              :showSearch="true"
              :loading="customers.loading"
              @onSelectSelectField="onSelectSelectField"
              @onChangeSelectField="onChangeSelectField"
              @onSearchSelectField="onSearchCustomers"
            />
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Código da Carta de Crédito</label>
              <a-input
                placeholder="Digite"
                v-model="creditLetter.filters.code"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Data de recebimento</label>
              <a-date-picker
                v-model="creditLetter.filters.receiptDate"
                format="DD/MM/YYYY"
                valueFormat="YYYY-MM-DD"
              />
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="8">
            <div class="travel-input">
              <label class="filled">Emissor</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                mode="multiple"
                optionFilterProp="txt"
                v-model="creditLetter.filters.issuer.selected"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="({ id, first_name, last_name }, index) of user.list"
                  :key="index"
                  :value="id"
                  :txt="first_name"
                >
                  {{ id }} - {{ first_name }}
                  {{ last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Vencimentos entre:</label>

              <a-range-picker
                v-model="creditLetter.filters.expires.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
              />
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Faixa de valor UTILIZADO</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="creditLetter.filters.spent.min"
                />
                a
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="creditLetter.filters.spent.max"
                />
              </a-input-group>
            </div>
          </a-col>
        </a-row>

        <a-divider class="mb-0 mt-10" />
        <a-button
          class="mt-10"
          type="primary"
          @click="onClickGetCreditLetters()"
          ><a-icon type="search" /> Filtrar</a-button
        >
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
// libs
import _ from "lodash";

// reuse
import userMixins from "@/mixins/user/userMixins";
import customerMixins from "@/components/customers/mixins/customerMixins";

// comps
import HayaSelectField from "@/components/general/fields/v-model/HayaSelectField.vue";

export default {
  name: "TravelProjectCreditLettersFilters",
  props: {
    creditLetter: Object,
  },
  components: { HayaSelectField },
  mixins: [userMixins, customerMixins],
  data() {
    return {
      selectFieldIsOpen: false,
    };
  },
  mounted() {
    this.user.pagination.perPage = 100;
    this.user.filters.status.selected = ["1"];
    this.getUsers();
    this.getCustomers();
  },
  methods: {
    onClickGetCreditLetters() {
      this.$emit("onClickGetCreditLetters", this.creditLetter.filters);
    },
    onSelectSelectField(value) {
      this.creditLetter.filters.customerId = value;
      this.customers.loading = false;
    },
    onChangeSelectField() {
      this.creditLetter.filters.customerId = "";
    },
    onSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.customers.filters.customerName = e;
        this.customers.list = [];
        this.getCustomers();
      }
    }, 600),
  },
};
</script>

<style lang="sass" scoped></style>
